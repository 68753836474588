import React from "react"

import "./../../css/lectures/lessons.css"


class LecturePage extends React.Component {

  insertSection = (lecture, section, key) => (
    <article className="section" key={key}>
      {
        !section.finale && (
          <h1>
            <span role="img" aria-label="book">{['📕', '📗', '📘', '📙'][key % 4]}</span>
            Section {section.idx}. {section.title}
          </h1>
        )
      }
      {
        section.finale && (
          <h1>
            <span role="img" aria-label="smile">😄</span>
            {section.title}
          </h1>
        )
      }
      <div className="desc">{section.desc}</div>
      <div className="lessons">
        {section.lessons.map((lesson, key) => this.insertLessons(lecture, section, lesson, key))}
      </div>
    </article>
  )

  insertLessons = (lecture, section, lesson, key) => (
    <article className="lesson" key={key}>
      <a href={`/@${lecture.alias}/${section.idx}-${lesson.idx}`}>
        <h1>
          {lesson.idx}. {lesson.title}
        </h1>
        {lesson.desc && (
          <p className="desc">{lesson.desc}</p>
        )}
      </a>
    </article>
  )

  render () {
    const lecture = this.props.lecture

    // console.log(
    //   lecture.sections
    //   .map(
    //     sec => sec.lessons
    //     .map(
    //       les => ` - ${sec.idx}-${les.idx}. ${les.title}`
    //     ).join('\n')
    //   ).join('\n')
    // );

    return (
      <section id="lecture" className="centered">
        <br/>
        <br/>
        <img src={`/images/lectures/${lecture.alias}/main.png`} alt="alias"></img>
        <br/>
        <br/>
        <br/>
        <h1>
          <span role="img" aria-label="books">📚</span> {lecture.title}
        </h1>
        <p className="desc larger">{lecture.desc}</p>
        <br/>
        <div id="sections">
          {lecture.sections.map((section, key) => this.insertSection(lecture, section, key))}
        </div>
      </section>
    )
  }
}

export default LecturePage