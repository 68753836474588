import React from "react"

import Layout from "../../components/js/layout"
import SEO from "../../components/js/seo"
import LecturePage from "../../components/js/lectures/lecture-page"

import lectureData from "./../../data/lectures/git-github.yaml"

class Lecture extends React.Component {

  render () {

    return (
      <Layout page="lectures">
        <SEO title={lectureData.title}/>
        <LecturePage lecture={lectureData}></LecturePage>
      </Layout>
    )
  }
}

export default Lecture